import React, { useState, useEffect, useCallback } from 'react';

import Header from '../Elements/Header';
import { Link, Navigate } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import Validate from '../Config/Validate';
import ImageViewer from 'react-simple-image-viewer';
import InputMask from 'react-input-mask';
export const ServiceEdit = () => {
    const [order, setOrder] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [nav, SetNav] = useState(0);
    let config = new Config();

    const [form, setForm] = useState(
        {
            value: {
                description: '',
                phoneNumber: '',
                contactPerson: '',
                userId: '',
                icon: []
            },

            error: {
                description: '',
                phoneNumber: '',
                contactPerson: '',
                userId: '',
                icon: []
            },
            focus: {
                description: '',
                phoneNumber: '',
                contactPerson: '',
                userId: '',
                icon: []
            }
        }
    );
    const setValue = (name, value) => {
        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }

    const getOrders = async () => {

        if (Store.services.time < Date.now()) {
            let data = await Get.get('api/paid-requests');

            Store.services.data = data.data;
            Store.services.time = Date.now() * 60 * 60;
        }

        return Store.services.data;
    }
    const getOrder = async (id) => {
        let orders = await getOrders();
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');

        let order = orders.find(el => el.paidRequestId == order_id);
        console.log(order)
        if (!order.hasOwnProperty('icon')) {
            order = await getOrderetch(order_id, order);
        }
        setForm(
            {
                ...form,
                value: {
                    ...form.value,
                    description: order.description,
                    phoneNumber: order.phoneNumber,
                    contactPerson: order.contactPerson,
                    icon: order.icon
                },
                focus: {
                    ...form.focus,

                    phoneNumber: 'focus',
                    contactPerson: 'focus',

                }
            }
        );
        setOrder(order);
    }
    const getOrderetch = async (id, order) => {
        let data = await Get.get('api/paid-requests/' + id);

        if (typeof data.status == 'object') {

            Store.services.data.map((el, index) => {
                if (el.paidRequestId == data.paidRequestId) {
                    Store.services.data[index] = data;
                }
            })
            setImages(data.icons);
        } else {

            return order;
        }
        return data;
    }
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const updateOrder = async (e) => {
        e.preventDefault();
        if (Validate.Empty(form.value.description)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    description: 'Это поле не может быть пустым'
                },
                focus: {
                    ...form.focus,
                    description: 'focus error'
                }
            });
        }
        if (Validate.Empty(form.value.phoneNumber)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phoneNumber: 'Это поле не может быть пустым'
                },
                focus: {
                    ...form.focus,
                    phoneNumber: 'focus error'
                }
            });
        }
        if (Validate.Empty(form.value.contactPerson)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    contactPerson: 'Это поле не может быть пустым'
                },
                focus: {
                    ...form.focus,
                    contactPerson: 'focus error'
                }
            });
        }

        let data = await Get.patch({
            description: form.value.description,
            phoneNumber: Validate.Phone(form.value.phoneNumber),
            contactPerson: form.value.contactPerson,
            appealCategoryId: order.appealCategoryId,
            appealTypeId: order.appealTypeId,
            personalAccountId: order.personalAccountId,
            statusId: order.statusId,
        }, 'api/paid-requests/' + order.paidRequestId);
        if (data.type == 'success') {
            setForm(
                {
                    value: {
                        description: '',
                        phoneNumber: '',
                        contactPerson: ''
                    },
                    error: {
                        description: '',
                        phoneNumber: '',
                        contactPerson: ''
                    },
                    focus: {}
                }
            );
            Store.services.time = 0;
            SetNav(1);

        }
    }
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    useEffect(() => {

        const as = async () => {
            getOrder();
        }
        as();
    }, []);
    if (order == null) {
        return
    }
    return (

        <>
            {nav == 1 ? <Navigate to={`/service?id=${order.paidRequestId}`} replace={true} /> :
                <div className='orders-page menu-container'>
                    <Header data={{ title: 'Услуга № ' + order.paidRequestNumber }} nazad={true} />
                    <div className='container'>
                        <form onSubmit={(e) => { updateOrder(e) }}>
                            <div className='pt-20'></div>
                            <div className='title-category'>Сообщение заявки</div>
                            <div className='pt-10'></div>
                            <div className={`input-block white-input`}>
                                <textarea id='description' rows='10' placeholder='Описание заявки' value={form.value.description} onChange={(e) => { setValue('description', e.target.value) }}></textarea>
                                <span className='error'>{form.error.description}</span>
                            </div>
                            {/* <div className='input-block pb-20 input-files-block'>

                            <label className='input-files'>
                                <input type='file' multiple accept="image/*" />
                                <span>Прикрепить файлы</span>

                            </label>
                        </div> */}
                            <div className='hr'></div>
                            <div className='title-modal pt-20 pb-40'>Контактные данные</div>
                            <div className={`input-block ${form.focus.contactPerson}`}>
                                <input id='UserName' type='text' value={form.value.contactPerson} onChange={(e) => { setValue('contactPerson', e.target.value) }} />
                                <label htmlFor='UserName'>Как к Вам обращаться?</label>
                                <span className='error'>{form.error.contactPerson}</span>
                            </div>

                            <div className='pt-40'></div>
                            <div className={`input-block ${form.focus.phoneNumber}`}>

                                <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" value={form.value.phoneNumber} onChange={(e) => { setValue('phoneNumber', e.target.value) }} />
                                <label htmlFor='Phone'>Телефон</label>
                                <span className='error'>{form.error.phoneNumber}</span>
                            </div>
                            <div className='input-block'>
                                <button type="submit" className='btn btn-orange'>Сохранить</button>
                            </div>
                            <div className='pt-20'></div>
                            <div className='pt-20'></div>
                        </form>
                    </div>
                </div >
            }
            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}

        </>
    )
}
export default ServiceEdit;
