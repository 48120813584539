import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Get from '../Config/Get';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import { v4 as uuidv4 } from 'uuid';
export const ModalAddOrderService2 = (props, ref) => {
    const [open, setOpen] = useState('');
    const [area, setArea] = useState([]);
    const [appeal, setAppeal] = useState([]);
    const [appealtype, setAppealType] = useState([]);
    const [user, setUser] = useState('');
    const [shects, setShets] = useState([]);
    const [reload, setReload] = useState(0);
    const [form, setForm] = useState(
        {
            value: {
                appealTypeId: [],
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: '',
                icon: []
            },

            error: {
                appealTypeId: '',
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: ''
            },
            focus: {
                appealTypeId: '',
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: ''
            }
        }
    );
    useImperativeHandle(ref, () => ({ modaOpen }));
    let tokenData = JSON.parse(localStorage.getItem('token'));

    let tokenDataDecode = {};
    if (typeof tokenData == 'object') {
        tokenDataDecode = jwtDecode(tokenData.token);

    }

    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const setCompany = async (schet) => {


        let tokenData = JSON.parse(localStorage.getItem('token'));


        let token = await Get.post({
            "personalAccountId": schet,
            "refreshToken": tokenData.refreshToken
        }, 'api/identity/token/refresh');
        localStorage.setItem('token', JSON.stringify(token));
        // console.log(jwtDecode(token.token));
        const { connectUser, events, reConnect } = Get.socket();
        reConnect();
        let address = await setAddress(schet);

        setForm({
            ...form,
            value: {
                ...form.value,
                address: address,
                personalAccountId: schet
            }

        })

    }
    const modaOpen = (data) => {

        setOpen('open');
        setAppeal(data);
        setReload(Math.random())
    }
    const setCheckboxValue = () => {

        setForm(
            {
                ...form,
                error: {
                    ...form.error,
                    appealTypeId: ''
                }
            }
        )

    }
    const modaClose = () => {
        setOpen('');
    }
    const getAppealType = async () => {
        if (Store.appealtypespaid.time < Date.now()) {
            let data = await Get.get('api/organization-services?offset=0&count=100');
            Store.appealtypespaid.data = data.data;
            Store.appealtypespaid.time = Date.now() * 60 * 60 * 24;

        }
        setAppealType(Store.appealtypespaid.data);
    }

    const getSchets = async () => {
        let data = [];

        if (Store.schets.time < Date.now()) {
            data = await Get.get('api/personal-accounts');
            if (data.status !== 404 && data.data !== undefined) {
                Store.schets.data = data.data;
                Store.schets.time = Date.now() * 60 * 60 * 24 * 7;
            }
        }

        let tokenData = JSON.parse(localStorage.getItem('token'));
        let tdata = jwtDecode(tokenData.token);

        let schetId = '';
        Store.schets.data.map((el, index) => {
            if (el.managingOrganizationId == tdata.managingOrganizationId) {
                schetId = el.personalAccountId;
            }
        });
        let address = await setAddress(schetId);


        setShets(Store.schets.data);
        return address;

    }

    const setAddress = async (id) => {
        let address = '';
        Store.schets.data.forEach((el, index) => {

            if (el.personalAccountId == id) {

                address = el.address;
                return;
            }
        });

        return address;
    }
    const getUser = async () => {
        let tokenData = JSON.parse(localStorage.getItem('token'));
        let tokenDataDecode = {};
        if (typeof tokenData == 'object') {
            tokenDataDecode = jwtDecode(tokenData.token);

        }
        if (Store.user.time < Date.now()) {
            let data = await Get.get('api/users/' + tokenDataDecode.user_id);
            Store.user.data = data;
            Store.user.time = Date.now() * 60 * 60 * 24 * 365;


        }
        return Store.user.data;


    }
    const scroll = (block) => {
        setTimeout(function () {

            let element = document.getElementById(block);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    }
    const inputFiles = async (e) => {
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let files = [...e.target.files];
        let images = await Promise.all(files.map(f => { return readAsDataURL(f) }));
        let iArr = form.value.icon;
        await Promise.all(images.map(async (el, index) => {

            el.data = await resizeImage(el.data);


            iArr.push(el.data);
        })
        );
        setForm({
            ...form,
            value: {
                ...form.value,
                icon: iArr
            }
        })
        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
    }
    const resizeImage = async (image) => {


        let p = await new Promise((resolve, reject) => {
            let img = new Image();
            img.src = image;
            img.onload = () => resolve({ height: img.height, width: img.width, img: img })
        });

        const os = navigator.platform;
        //Linux armv81
        let format = 'image/jpeg'
        if (os.indexOf('Linux') !== -1 || os.indexOf('Android') !== -1) {
            format = 'image/webp';
        }
        var canvas = document.getElementById('ImageLoaded');
        var ctx = canvas.getContext('2d');
        canvas.width = p.width * 0.5;
        canvas.height = p.height * 0.5;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(p.img, 0, 0, p.width * 0.5, p.height * 0.5);
        let data_url = canvas.toDataURL(format);
        return data_url;
    }
    const readAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve({ data: fileReader.result, name: file.name, size: file.size, type: file.type });

            }
            fileReader.readAsDataURL(file);
        })
    }

    const removeImage = (index) => {

        form.value.icon.splice(index, 1);
        let files = form.value.icon;
        console.log(files)
        setForm({
            ...form,
            value: {
                ...form.value,
                icon: [...form.value.icon]
            }
        })
    }
    const getDefaultStatus = async () => {
        let res = await Get.get('api/statuses?type=Paid');
        let statusID = null;
        if (res.hasOwnProperty('data') && res.data.length > 0) {
            let defaultStatus = res.data.filter(item => item.isDefault === true)[0];
            statusID = defaultStatus.statusId;
        }
        return statusID;
    }
    const searchNameAppeal = (appeals, id) => {
        let name = 'Имя услуги не получено';
        appeals.forEach(element => {

            if (element.organizationServiceId == id) {

                name = element.name;
            }
        });

        return name;
    }
    const createOrder = async (e) => {
        e.preventDefault();
        let selected = [...document.querySelectorAll('.checkboxed[type=checkbox]:checked')].map(checkbox => checkbox.value);
        if (selected.length == 0) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    appealTypeId: 'Выберите тип обращения'
                },
                focus: {
                    ...form.focus,
                    appealTypeId: 'focus error'
                }
            });
            scroll('scroolBlock1');
            return;
        }
        if (Validate.Empty(form.value.description)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    description: 'Заполните описание заявки'
                },
                focus: {
                    ...form.focus,
                    description: 'focus error'
                }
            });
            scroll('scroolBlock2');
            return;
        }
        let statusID = await getDefaultStatus();
        let services = [];
        selected.map(id => {
            services.push({
                organizationServiceId: id,
                count: 1,
                price: 1,
                name: searchNameAppeal(appealtype, id)

            });
        });

        let uuid = uuidv4();
        let data = await Get.post({
            requestId: uuid,
            description: form.value.description,
            contactPerson: form.value.contactPerson,
            phoneNumber: Validate.Phone(form.value.phoneNumber),
            appealCategoryId: appeal.appealCategoryId,
            icons: form.value.icon,
            personalAccountId: form.value.personalAccountId,
            statusId: statusID,
            organizationServices: services
        }, 'api/paid-requests');
        if (data.type == 'success' || data.hasOwnProperty('requestId')) {
            setForm({
                ...form,
                value: {
                    ...form.value,
                    description: '',
                    appealTypeId: [],
                    icon: []
                }
            });
            document.querySelectorAll('input[type="checkbox"]').forEach((n) => {
                n.checked = false;
            });
            modaClose();
            props.close()
        }
    }

    useEffect(() => {
        const as = async () => {
            let userdata = await getUser();


            let tokenData = JSON.parse(localStorage.getItem('token'));
            let tokenDataDecode = {};
            if (typeof tokenData == 'object') {
                tokenDataDecode = jwtDecode(tokenData.token);
                let user = {

                    name: tokenDataDecode.name,
                    phone: userdata.phoneNumber,
                    email: tokenDataDecode.email,
                    user_id: tokenDataDecode.user_id
                };

                if (!localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    user = JSON.parse(localStorage.getItem('user'));
                }

                let address = await getSchets();
                let schetId = '';
                Store.schets.data.map((el, index) => {
                    if (el.managingOrganizationId == tokenDataDecode.managingOrganizationId) {
                        schetId = el.personalAccountId;
                    }
                });

                setForm({
                    ...form,
                    value: {
                        ...form.value,
                        contactPerson: user.name,
                        address: address,
                        phoneNumber: user.phone,
                        userId: user.user_id,
                        personalAccountId: schetId
                    },
                    focus: {
                        ...form.focus,
                        contactPerson: 'focus',
                        phoneNumber: 'focus'
                    }
                })

                getAppealType();
                setUser(user)

            }
        }
        as();
    }, [reload])

    return (
        <div className={`overflow ${open}`}>

            <div className='modal-order-container'>
                <div className='header-modal flex-betwen flex-wrap'>
                    <div className='close-container'>
                        <div className='close-modal' onClick={() => { modaClose() }}></div>
                    </div>
                    <div className='title grow'>{appeal.name}</div>
                </div>
                <div className='body-modal uslugi-modal'>
                    <form onSubmit={(e) => { createOrder(e) }}>
                        <div className='container'>
                            <div className='title-modal pt-40 pb-20'>Выберите тип услуги</div>
                            <div className="desc-body desc-servicep pb-20">Компетентные мастера в Химках. Прием заявок круглосуточно и без выходных</div>
                            <div className='hr'></div>
                            <div id='scroolBlock1'></div>
                            <div className='title-modal pt-20 pb-20'>Выберите тип обращения</div>
                            <div className="desc-body desc-servicep pb-20">Вы можете выбрать несколько вариантов</div>
                            <div className='error'>{form.error.appealTypeId}</div>
                            <div className='appeal-types-wrap pb-20'>

                                {appealtype.map((el, index) => {

                                    return (

                                        <div className='appeal-type-item-checkbox checkbox-custom' key={index}>

                                            <input type='checkbox' className='checkboxed' id={`radioappealtype${index}`} name='appealtype' value={el.organizationServiceId} onChange={() => { setCheckboxValue() }} />
                                            <label htmlFor={`radioappealtype${index}`}>
                                                {el.name}
                                            </label>
                                        </div>
                                    )

                                })}
                            </div>
                            <div className='hr'></div>
                            <div className='title-modal pt-20 pb-20'>Описание заявки</div>
                            <div id='scroolBlock2'></div>
                            <div className={`input-block ${form.focus.description}`}>
                                <textarea name='description' id='description' rows='10' value={form.value.description} onChange={(e) => { setValue('description', e.target.value) }} placeholder='Описание заявки'></textarea>
                                <span className='error'>{form.error.description}</span>
                            </div>
                            <div className='input-block pb-20 input-files-block'>
                                <div className='files-wrap flex-start flex-wrap'>
                                    {form.value.icon.map((el, index) => {
                                        return (
                                            <div className='prevyu-image' key={index}>
                                                <span className='remove' onClick={() => { removeImage(index) }}>&times;</span>
                                                <img src={el} alt="Картинка" />
                                            </div>
                                        )

                                    })}
                                </div>
                                <label className='input-files'>
                                    <input type='file' multiple onChange={(e) => { inputFiles(e) }} accept="image/*" />
                                    <span>Прикрепить файлы</span>

                                </label>
                            </div>
                            <div className='hr'></div>
                            <div className='title-modal pt-20 pb-20'>Контактные данные</div>
                            <div className='orange-label'>
                                <div className='pt-20'></div>
                                <div className='input-block focus'>

                                    <input id='address' type='text' placeholder='Адрес' readOnly={true} value={form.value.address} />
                                    <label>Адрес</label>

                                </div>
                                <div className='pt-40'></div>
                                <div className='input-block focus'>
                                    <select onChange={(e) => { setCompany(e.target.value) }} defaultValue={form.value.personalAccountId}>
                                        {shects.map(

                                            (el, index) => {
                                                return (
                                                    <option selected={tokenDataDecode.managingOrganizationId == el.managingOrganizationId ? true : false} key={index} value={el.personalAccountId}>{el.account}</option>
                                                )
                                            }
                                        )}
                                    </select>
                                    <label>Выберите счет</label>
                                </div>
                                <div className='pt-40'></div>
                                <div className={`input-block ${form.focus.contactPerson}`}>
                                    <input id='UserName' type='text' onChange={(e) => { setValue('contactPerson', e.target.value) }} value={form.value.contactPerson} />
                                    <label htmlFor='UserName'>Как к Вам обращаться?</label>
                                    <span className='error'>{form.error.contactPerson}</span>
                                </div>

                                <div className='pt-40'></div>
                                <div className={`input-block ${form.focus.phoneNumber}`}>

                                    <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" onChange={(e) => { setValue('phoneNumber', e.target.value) }} value={form.value.phoneNumber} />
                                    <label htmlFor='Phone'>Телефон</label>
                                    <span className='error'>{form.error.phoneNumber}</span>
                                </div>
                                <div className='pt-10'></div>
                                <div className='pt-40'></div>
                                <div className='input-block'>
                                    <button type="submit" className='btn btn-orange'>Создать услугу</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <canvas id='ImageLoaded' className='image-loaded-canvas'></canvas>
        </div>

    )
}
export default forwardRef(ModalAddOrderService2);
