import React, { useState, useEffect, useCallback, useRef } from 'react';

import Header from '../Elements/Header';
import { Link, Navigate } from 'react-router-dom';
import Get from '../Config/Get';
import ModalInfo from '../Elements/ModalInfo';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ImageViewer from 'react-simple-image-viewer';

export const Order = () => {
    const [order, setOrder] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [nav, SetNav] = useState(0);
    let config = new Config();

    const modal = useRef(null);
    const getCompleteStatus = async () => {
        let res = await Get.get('api/statuses?type=Normal');
        if (res.hasOwnProperty('data') && Array.isArray(res.data)) {
            let completeStatus = res.data.filter(item => item.name === 'Выполнено')[0];
            return completeStatus.statusId;
        }
        return false;
    }
    const OrderComplete = async (order) => {
        let statusID = await getCompleteStatus();
        if (statusID) {
            console.log(statusID);
            let data = await Get.patch({
                description: order.description,
                phoneNumber: order.phoneNumber,
                contactPerson: order.contactPerson,
                appealCategoryId: order.appealCategoryId,
                appealTypeId: order.appealTypeId,
                areaRoomId: order.areaRoomId,
                personalAccountId: order.personalAccountId,
                statusId: statusID,
            }, 'api/requests/' + order.requestId);
            if (data.type == 'success') {
                Store.orders.time = 0;
                SetNav(1);
            }
        }
    }


    const deleteOrder = async () => {
        Store.orders.time = 0;
        await Get.delete('api/requests/' + order.requestId)
        SetNav(1);
    }
    const deleteOrderModal = () => {
        if (modal.current !== null) {
            modal.current.modaOpen({
                title: 'Удаление заявки',
                content: 'Вы действительно хотите отменить заявку №' + order.requestNumber,
                func: deleteOrder

            });
        }
    }
    const getOrders = async () => {

        if (Store.orders.time < Date.now()) {
            let data = await Get.get('api/requests');

            Store.orders.data = data.data;
            Store.orders.time = Date.now() * 60 * 60;
        }

        return Store.orders.data;
    }
    const getOrder = async (id) => {
        let orders = await getOrders();
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');

        let order = orders.find(el => el.requestId == order_id);
        if (!order.hasOwnProperty('icon')) {
            order = await getOrderetch(order_id, order);
        }

        setOrder(order);
    }
    const getOrderetch = async (id, order) => {
        let data = await Get.get('api/requests/' + id);

        if (typeof data.status == 'object') {

            Store.orders.data.map((el, index) => {
                if (el.requestId == data.requestId) {
                    Store.orders.data[index] = data;
                }
            })
            setImages(data.icons);
        } else {

            return order;
        }
        return data;
    }
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    useEffect(() => {

        const as = async () => {
            getOrder();
        }
        as();
    }, []);
    if (order == null) {
        return
    }
    return (
        <>
            {nav == 1 ? <Navigate to='/orders' /> :
                <div className='orders-page menu-container'>
                    <Header data={{ title: 'Заявка № ' + order.requestNumber }} nazad={true} />
                    <div className='container'>
                        <div className='pt-20'></div>
                        <div className='status-block title-status'>Статус: <span className='status-value' style={{ color: order.status.color }}>{order.status.name}</span></div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='date-block'>{config.dateFormatTimestapTime(order.createdOn)}</div>
                        <div className='pt-20'></div>
                        <div className='app-category title-category'>{order.appealType.name}</div>
                        <div className='pt-10'></div>
                        <div className='message-block'>{order.description}</div>
                        <div className='pt-20'></div>

                        {order.hasOwnProperty('icons') && order.icons.length > 0 ?

                            <div className='images-block'>

                                <div className='pt-20'></div>
                                <div className='title-label'>Изображения</div>
                                <div className='pt-15'></div>
                                <div className='prevyou-wrap-images flex-start flex-wrap'>
                                    {order.icons.map((el, index) => {
                                        return (
                                            <div className='prevyou-image' key={index}>
                                                <img src={el} onClick={() => openImageViewer(index)} />
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className='pt-20'></div>
                            </div>

                            : ''}

                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Контактное лицо</div>
                        <div className='pt-15'></div>
                        <div className='value-label'>{order.contactPerson}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Телефон для связи</div>
                        <div className='pt-15'></div>
                        <div className='value-label orange'>{config.formatNumber(order.phoneNumber)}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Адрес</div>
                        <div className='pt-15'></div>
                        <div className='value-label'>{order.personalAccount.address}</div>
                        <div className='pt-20'></div>
                        <div className='hr'></div>
                        <div className='pt-20'></div>
                        <div className='title-label'>Лицевой счет</div>
                        <div className='pt-15'></div>
                        <div className='value-label orange'>{config.SchetFormat(order.personalAccount.account)}</div>
                        <div className='pt-40'></div>
                        <div className='input-block'>
                            {order.status.name == 'Ожидает принятия в работу' ?
                                <Link className='btn btn-white' to={`/orderedit?id=${order.requestId}`}>Редактировать</Link>
                                : ''}

                        </div>
                        <div className='input-block'>
                            {order.status.name == 'Ожидает принятия в работу' ?
                                <button className='btn btn-red' onClick={() => { deleteOrderModal() }}>Отменить</button>
                                : ''}

                        </div>
                        <div className='input-block'>
                            {order.hasOwnProperty('applicantChatId') ?
                                <Link className='btn btn-orange' to={`/chat?id=${order.applicantChatId}&order=${order.requestNumber}`}>Чат с сотрудником</Link>
                                : ''}

                        </div>
                        <div className='input-block'>
                            {order.status.name == 'Ожидает подтверждения' ?
                                <button type='buttom' className='btn btn-green' onClick={() => { OrderComplete(order) }}>Подтвердить выполнение</button>
                                : ''}

                        </div>
                        <div className='pt-20'></div>
                        <div className='pt-20'></div>
                    </div>
                </div >
            }
            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            <ModalInfo ref={modal} />

        </>
    )
}
export default Order;