import React, { useState, useEffect } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import InputPass from '../Elements/InputPass';
import Validate from '../Config/Validate';
import InputMask from 'react-input-mask';
export const EditProfil = () => {
    let config = new Config();
    const [uuid, setUuid] = useState('');
    const [form, setForm] = useState(
        {
            value: {
                name: '',
                phone: '',
                email: '',
                notifyEmail: false
            },

            error: {
                name: '',
                phone: '',
                email: ''
            },
            focus: {
                name: '',
                phone: '',
                email: ''
            }
        }
    );
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const checkChange = () => {
        let ch = false;
        let checkboxes = document.querySelectorAll('input.checkboxed:checked');
        if (checkboxes.length > 0) {
            ch = true;
        }
        setForm({
            ...form, value: { ...form.value, notifyEmail: ch }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();

        if (Validate.Empty(form.value.name)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }

        if (Validate.Cirilic(form.value.name)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле должно содержать только кириллицу'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.name, 3, 15)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    name: 'Поле должно содержать не менее 3 и не более 15 символов'
                },
                focus: {
                    ...form.focus,
                    name: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.phone)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        // if (Validate.Count(form.value.phone, 16, 16)) {
        //     setForm({
        //         ...form,
        //         error: {
        //             ...form.error,
        //             phone: 'Не корректный номер'
        //         },
        //         focus: {
        //             ...form.focus,
        //             phone: 'focus error'
        //         }
        //     });
        //     return;
        // }
        if (Validate.Empty(form.value.email)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        if (Validate.Email(form.value.email)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    email: 'Не корректный e-mail'
                },
                focus: {
                    ...form.focus,
                    email: 'focus error'
                }
            });
            return;
        }
        console.log(form.value.notifyEmail)
        let res = await Get.patch({
            name: form.value.name,
            userType: 'CitizenMobile',
            phoneNumber: Validate.Phone(form.value.phone),
            email: form.value.email,
            notifyEmail: form.value.notifyEmail == undefined ? false : form.value.notifyEmail
        }, 'api/users/' + uuid);
        console.log(res)
        let userdata = JSON.parse(localStorage.getItem('user'));
        userdata.name = form.value.name;
        userdata.phone = form.value.phone;
        userdata.email = form.value.email;
        userdata.notifyEmail = form.value.notifyEmail;
        localStorage.setItem('user', JSON.stringify(userdata));
    }


    useEffect(() => {
        const as = async () => {
            let userdata = JSON.parse(localStorage.getItem('user'));


            if (typeof userdata == 'object') {

                setForm({
                    ...form,
                    focus: {
                        ...form.focus,
                        email: 'focus',
                        name: 'focus',
                        phone: 'focus'
                    },
                    value: {
                        name: userdata.name,
                        phone: userdata.phone,
                        email: userdata.email,
                        notifyEmail: userdata.notifyEmail
                    }
                });
                setUuid(userdata.user_id);

            }

        }
        as();


    }, [])
    return (
        <div className='profil-page menu-container'>
            <Header data={{ title: 'Контактные данные' }} nazad={true} />
            <div className='container pt-40'>
                <div className='login-form'>
                    <form onSubmit={(e) => { sendForm(e) }}>
                        <div className={`input-block ${form.focus.name}`}>
                            <input type="text" id="Name" value={form.value.name} onChange={(e) => { setValue('name', e.target.value) }} />
                            <label htmlFor="Name">Как к Вам обращаться?</label>
                            <span className='error'>{form.error.name}</span>
                        </div>
                        <div className={`input-block ${form.focus.phone}`}>
                            <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" value={form.value.phone} onChange={(e) => { setValue('phone', e.target.value) }} />
                            <label htmlFor="Phone">Телефон</label>
                            <span className='error'>{form.error.phone}</span>
                        </div>
                        <div className={`input-block ${form.focus.email}`}>
                            <input type="text" id="Email" value={form.value.email} onChange={(e) => { setValue('email', e.target.value) }} />
                            <label htmlFor="Email">E-mail</label>
                            <span className='error'>{form.error.email}</span>
                        </div>
                        <div className='pt-20'></div>
                        <div className='checkbox-custom'>

                            <input type='checkbox' checked={form.value.notifyEmail !== undefined ? form.value.notifyEmail : false} className='checkboxed' id="sendMail" name='notifyEmail' value="1" onChange={() => { checkChange() }} />
                            <label htmlFor={`sendMail`}>
                                Отправлять оповещения на почту
                            </label>
                        </div>
                        <div className='pt-20'></div>
                        <div className='input-block'>
                            <button type='submit' className='btn btn-orange'>Сохранить</button>
                        </div>

                    </form>
                </div>
            </div>

            <Menu link='/profil' />

        </div>
    )
}
export default EditProfil;