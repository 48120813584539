import React, { useState, useEffect, useRef } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ModalAddOrder1 from '../Elements/ModalAddOrderService1';
import ModalNoSchet from '../Elements/ModalNoSchet';
import ModalPay from '../Elements/ModalPay';
export const Services = () => {
    const modNoschet = useRef(null);
    const modal1 = useRef(null);
    const [shects, setShets] = useState([]);
    let config = new Config();
    const modalP = useRef(null);
    const [paydata, setPayData] = useState({
        btn: ''
    })
    let tokenData = JSON.parse(localStorage.getItem('token'));
    // console.log(tokenData);
    let tokenDataDecode = {};
    if (typeof tokenData == 'object') {
        tokenDataDecode = jwtDecode(tokenData.token);
        // console.log(tokenDataDecode);
    }
    const [status, setStatus] = useState('active');

    const [orders, setOrders] = useState([])
    const getOrders = async () => {

        if (Store.services.time < Date.now()) {
            let tokenData = JSON.parse(localStorage.getItem('token'));
            tokenDataDecode = jwtDecode(tokenData.token);
            // console.log(tokenDataDecode.user_id);
            let data = await Get.get('api/paid-requests?userId=' + tokenDataDecode.user_id);
            Store.services.data = data.data;
            Store.services.time = Date.now() * 60 * 60;



        }
        setOrders(Store.services.data);
    }
    const setCompany = async (schet) => {
        let tokenData = JSON.parse(localStorage.getItem('token'));


        let token = await Get.post({
            "personalAccountId": schet,
            "refreshToken": tokenData.refreshToken
        }, 'api/identity/token/refresh');
        localStorage.setItem('token', JSON.stringify(token));

        Store.services.time = 0;
        getOrders();
        ConnectSocket();
    }
    const ConnectSocket = async () => {
        const { connectUser, events, reConnect } = Get.socket();
        reConnect();


        const PaidRequestChanged = () => {

            Store.services.time = 0;
            getOrders();

        };
        const PaymentCreated = (data) => {
            payOpen(data);
        }
        events(null, null, PaidRequestChanged, PaymentCreated);
    }
    const createPay = async (order, e) => {
        if (!order.hasOwnProperty('paymentId')) {
            e.target.classList.add('loaded')
            let res = await Get.put({}, 'api/paid-requests/' + order.paidRequestId + '/start-payment', false);
            Store.services.time = 0;
            getOrders();
        } else {
            payOpen({ paymentId: order.paymentId })
        }

    }
    const payOpen = (data) => {
        var elems = document.querySelectorAll(".btn");

        [].forEach.call(elems, function (el) {
            el.classList.remove("loaded");
        });
        modalP.current.modaOpen(data);
    }
    const getCompleteStatus = async () => {
        let res = await Get.get('api/statuses?type=Paid');
        if (res.hasOwnProperty('data') && Array.isArray(res.data)) {
            let completeStatus = res.data.filter(item => item.name === 'Выполнено')[0];
            return completeStatus.statusId;
        }
        return false;
    }
    const OrderComplete = async (order) => {
        let statusID = await getCompleteStatus();
        if (statusID) {
            console.log(statusID);
            let data = await Get.patch({
                description: order.description,
                phoneNumber: order.phoneNumber,
                contactPerson: order.contactPerson,
                appealCategoryId: order.appealCategoryId,
                appealTypeId: order.appealTypeId,
                personalAccountId: order.personalAccountId,
                statusId: statusID,
            }, 'api/paid-requests/' + order.requestId);
            if (data.type == 'success') {
                Store.orders.time = 0;
                getOrders();
            }
        }
    }
    const openModal1 = () => {
        if (shects.length > 0) {
            modal1.current.modaOpen();
        } else {
            ModalNoSchetOpen();
        }

    }
    const counterPrice = (organizationServices) => {
        let ptice = 0
        if (Array.isArray(organizationServices)) {
            organizationServices.forEach(item => {
                ptice += item.price
            })
        }
        return ptice
    }
    const scroll = () => {
        setTimeout(function () {
            let element = document.getElementById('scrollBlock');

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    }
    const getSchets = async () => {
        let data = [];
        if (Store.schets.time < Date.now()) {
            data = await Get.get('api/personal-accounts');
            if (data.status !== 404) {
                Store.schets.data = data.data;
                Store.schets.time = Date.now() * 60 * 60 * 24 * 7;
            }
        }
        setShets(Store.schets.data);
        if (Store.schets.data.length == 0) {
            ModalNoSchetOpen();
        }
    }
    const ModalNoSchetOpen = () => {
        if (modNoschet.current !== null) {
            modNoschet.current.modaOpen();
        }

    }
    useEffect(() => {
        ConnectSocket();
    }, [])
    useEffect(() => {
        getOrders();
        getSchets();
    }, [])
    return (
        <>
            <div className='orders-page menu-container'>
                <Header data={{ title: ' Услуги' }} />

                <div className='tab-links-wrap pt-20'>
                    <div className='container'>
                        <ul className='tab-links flex-betwen'>
                            <li>
                                <Link to='/orders'>Заявки</Link>
                            </li>
                            <li>
                                <Link className="active" to='/services'>Услуги</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='container pt-20'>
                    <div className='btns-container-status flex-start'>
                        <button type='button' className={`btn ${status == 'active' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('active')}>Активные</button>
                        <button type='button' className={`btn ${status == 'complete' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('complete')}>Выполненные</button>
                    </div>
                </div>
                <div className='container pt-20'></div>
                <div className='container pt-40'>

                    <div className='input-block focus'>

                        <select onChange={(e) => { setCompany(e.target.value) }}>
                            {shects.map(

                                (el, index) => {

                                    return (
                                        <option selected={tokenDataDecode.managingOrganizationId == el.managingOrganizationId ? true : false} key={index} value={el.personalAccountId}>{el.account}</option>
                                    )
                                }
                            )}
                        </select>
                        <label>Выберите счет</label>
                    </div>
                </div>
                <div className='order-container container'>

                    {orders.length > 0 ? orders.map((el, index) => {

                        if (status == 'active' && el.status.name !== 'Выполнено') {
                            return (
                                <div className='order-item' key={index}>

                                    <Link to={`/service?id=${el.paidRequestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Услуга № {el.paidRequestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                    {el.status.name == 'Ожидает оплаты' ?
                                        <div className='pt-10 input-block'>
                                            <button
                                                type='buttom'
                                                className={`btn btn-green`}
                                                onClick={(e) => { createPay(el, e) }}
                                            >
                                                Оплатить {counterPrice(el.organizationServices)} р.
                                            </button>
                                        </div>
                                        : ''}
                                    {el.status.name == 'Ожидает подтверждения' ? <div className='pt-10 input-block'><button type='buttom' className={`btn btn-green`} onClick={() => { OrderComplete(el) }}>Подтвердить выполнение</button></div> : ''}
                                </div>
                            );
                        }
                        if (status == 'complete' && el.status.name == 'Выполнено') {
                            return (
                                <div className='order-item' key={index}>

                                    <Link to={`order?id=${el.requestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Услуга № {el.paidRequestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                </div>
                            );
                        }



                    }) : <div className='no-orders-container container'><div className="text-alert">Здесь будут отображаться ваши заявки и услуги. Вы можете создать их здесь</div></div>}
                </div>
                <Menu link='/orders' />

            </div>
            <ModalAddOrder1 ref={modal1} getOrders={() => { getOrders() }} />
            <div className='plus-orders' onClick={() => openModal1()}></div>
            <ModalNoSchet ref={modNoschet} />
            <ModalPay ref={modalP} />
        </>
    )
}
export default Services;