import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import Get from '../Config/Get';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ModalAddOrderService2 from './ModalAddOrderService2';
export const ModalAddOrderService1 = (props, ref) => {
    const [open, setOpen] = useState('');
    const [appeal, setAppeal] = useState([]);
    const modal2 = useRef(null);
    useImperativeHandle(ref, () => ({ modaOpen }));

    const modaOpen = () => {
        setOpen('open');
    }
    const modaClose = () => {
        setOpen('');
    }
    const modaCloseOrderCreate = () => {
        Store.services.time = 0;
        props.getOrders();
        setOpen('');
    }
    const getAppeal = async () => {

        if (Store.appealpaid.time < Date.now()) {

            let data = await Get.get('api/appeal-categories?offset=0&count=100&type=Paid');
            Store.appealpaid.data = data.data;
            Store.appealpaid.time = Date.now() * 60 * 60 * 24;


        }
        if (Store.appealpaid.data !== undefined) {
            setAppeal(Store.appealpaid.data);
        }
    }
    const openModal2 = (data) => {

        modal2.current.modaOpen(data);
    }
    useEffect(() => {
        getAppeal();
    }, [])
    return (
        <>
            <div className={`overflow ${open}`}>
                <div className='modal-order-container'>
                    <div className='header-modal flex-betwen flex-wrap'>
                        <div className='close-container'>
                            <div className='close-modal' onClick={() => { modaClose() }}></div>
                        </div>
                        <div className='title grow'>Создание услуги</div>
                    </div>
                    <div className='body-modal'>
                        <div className='container'>
                            <div className='title-modal pt-40 pb-20'>Выберите категорию обращения</div>
                            <div className='flex-start flex-wrap area-wrap'>

                                {appeal.map((el, index) => {
                                    return (
                                        <div className='area-item' key={index} onClick={() => { openModal2(el) }}>

                                            <div className='area-img'>
                                                <img src={el.icon} alt={el.name} />
                                            </div>
                                            <div className='area-title pt-20'>
                                                {el.name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddOrderService2 ref={modal2} close={() => modaCloseOrderCreate()} />
        </>
    )
}
export default forwardRef(ModalAddOrderService1);
